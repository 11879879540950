import { Routes } from '@angular/router';
import { AuthGuard } from '@view/auth.guard';
import { LayoutAIComponent } from '@view/layouts/ai/layout-ai.component';
import { LayoutFullComponent } from '@view/layouts/full/layout-full.component';
import { AccountPageComponent } from '@view/pages/account-page/account-page.component';
import { ChatbotComponent } from '@view/pages/chatbot/chatbot.component';
import { FileManagerComponent } from '@view/pages/file-manager/file-manager.component';
import { PageNotFoundComponent } from '@view/pages/page-not-found/page-not-found.component';
import { WorkspaceDetailsComponent } from '@view/pages/workspace/workspace-details/workspace-details.component';
import { WorkspaceComponent } from '@view/pages/workspace/workspace.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutAIComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/ai/chatbot',
        pathMatch: 'full',
      },
      {
        path: 'files-manager',
        component: FileManagerComponent,
      },
      {
        path: 'account',
        children: [
          { path: '', component: AccountPageComponent },
        ],
      },
    ]
  },
  {
    path: 'ai',
    component: LayoutAIComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'chatbot',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ChatbotComponent,
          },
          {
            path: 'chat/:id',
            component: ChatbotComponent,
          },
        ],
      },
      {
        path: 'workspace',
        children: [
          {
            path: '',
            component: WorkspaceComponent,
          },
          {
            path: 'view/:id',
            component: WorkspaceDetailsComponent,
          },
        ]
      }
    ],
  },
  {
    path: '',
    component: LayoutFullComponent,
    children: [
      { path: 'sign-in', loadComponent: () => import('@view/pages/sign-in/ai/sign-in.component').then(m => m.AISignInComponent) },
    ]
  },
  {
    path: 'sign-up',
    children: [
      {
        path: '',
        loadComponent: () => import('@view/pages/sign-up/ai/sign-up.component').then(m => m.AISignUpComponent),
      },
      {
        path: 'unverified-account',
        loadComponent: () =>
          import('@view/pages/sign-up/unverified-account.component').then(m => m.UnverifiedAccountComponent),
      },
      {
        path: 'resend-verified',
        loadComponent: () => import('@view/pages/sign-up/resend-verified.component').then(m => m.ResendVerifiedComponent),
      },
    ],
  },
  {
    path: 'sign-out',
    loadComponent: () => import('@view/pages/sign-out/sign-out.component').then(m => m.SignOutComponent),
  },
  {
    path: 'reset-password',
    loadComponent: () => import('@view/pages/reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
  },
  {
    path: 'terms-of-service',
    loadComponent: () =>
      import('@view/pages/terms-of-service/terms-of-service.component').then(m => m.TermsOfServiceComponent),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
];