<div
  #workspace
  class="workspaceWrapper h-full overflow-hidden"
  [ngClass]="{ hideSources: !showSources, showSourceDetails }"
>
  <div class="workspace-details chatbox">
    <div class="flex w-full justify-between">
      <!-- <h3>{{ details.name }}</h3> -->
      <button class="btn-icon link" (click)="goBack()"><i class="icon-ArrowBackward"></i></button>
      <div class="flex gap-4">
        <button class="btn-icon link small" (click)="editDialog.showModal()">
          <i class="icon-DotsHorizontal"></i>
        </button>
        @if(!showSources){
        <button class="btn-icon link small" (click)="showSources = true">
          <i class="icon-Sidebar"></i>
        </button>
        }
      </div>
    </div>
    @if(messages.length>0){
    <div class="w-full flex justify-center overflow-hidden">
      <div class="w-full max-w-[650px]">
        <messages
          [messages]="messages"
          [isThinking]="isThinking"
          (isWritingEvent)="updateThinking($event)"
          [userId]="currentUser().uuid"
          [chatId]="workspaceId"
          [fileService]="fileService"
          (likeEvent)="onLikeMessage($event)"
          [suggestions]="details.suggestions"
          (onClickSuggestion)="sendMessage($event)"
          [interactiveModels]="selectedModels"
          (undoEvent)="onUndoMessage($event)"
          (removeEvent)="onRemoveMessage($event)"
        />
      </div>
    </div>
    } @if(messages.length==0&&!showActions){
    <div class="w-full h-full"></div>
    } @if(showActions){
    <!-- <div class="col p-4 gap-2.5 w-full rounded-lg goal">
        <span class="flex gap-1"><i class="icon-Target04"></i>Mục tiêu không gian làm việc</span>
        <h6 class="semi">{{ details.goal }}</h6>
      </div> -->
    <div class="w-full overflow-hidden col gap-6 justify-center">
      <div class="w-full flex justify-center">
        <img src="/assets/images/Workspace Avatar.svg" class="w-[60px] h-[60px]" />
      </div>
      <div class="workspace-actions">
        <button [disabled]="updatingSource" (click)="sendMessage('Tạo Summary')">
          <i class="icon-MessageTextSquare text-emerald-0"></i>
          <span class="font-semibold">Tạo Summary</span>
        </button>
        <button [disabled]="updatingSource" class="col gap-2.5 rounded-lg" (click)="sendMessage('Tạo FAQ')">
          <i class="icon-MessageQuestionCircle text-lightBlue-1"></i>
          <span class="font-semibold">Tạo FAQ</span>
        </button>
        <button [disabled]="updatingSource" class="col gap-2.5 rounded-lg" (click)="sendMessage('Tạo Key-terms')">
          <i class="icon-MessageSmileSquare text-orange"></i>
          <span class="font-semibold">Tạo Key-terms</span>
        </button>
        <button [disabled]="updatingSource" class="col gap-2.5 rounded-lg" (click)="sendMessage('Tạo Goal Feeder')">
          <i class="icon-Target04 text-red-0"></i>
          <span class="font-semibold">Tạo Goal Feeder</span>
        </button>
      </div>
    </div>
    }
    <!-- <div class="w-full flex justify-center overflow-hidden">
      <div class="w-full max-w-[960px] px-4 suggestions col gap-4 max-h-[222px] overflow-y-auto">
        @for(suggestion of details.suggestions;track suggestion;let i = $index){
        <button
          class="rounded-full w-full h-14 px-3 py-2 gap-14 items-center justify-between"
          (click)="sendMessage(suggestion); showActions = false"
        >
          <span>{{ suggestion }}</span>
          <button class="btn-icon link"><i class="icon-ArrowForward"></i></button></button
        >}
      </div>
    </div> -->
    <div class="w-full flex justify-center relative z-[1]">
      <div class="w-full max-w-[720px] col gap-4 pb-4">
        <div class="relative max-h-9 flex items-center">
          <div #sourcesScroll class="w-full overflow-auto hide-scrollbar scroll-smooth flex gap-2.5 px-4">
            @for(source of sources;track source.id;let i = $index){ @if(source.using){
            <div class="chip orange">
              <span
                class="cursor-pointer"
                (click)="selectingSource = source.id; showSources = true; showSourceDetails = true"
                ><i class="icon-File01 small"></i>{{ source.hashTag }}</span
              >
              <button (click)="updateUsing(source.id, false)"><i class="icon-XClose"></i></button>
            </div>
            } }
          </div>
          @if(isAnySourceUsing()){
          <div class="absolute left-0 top-0 h-9 flex items-center z-[1]">
            <button
              class="btn-icon link small bg-[linear-gradient(to_right,var(--color-primaryBlue-900),var(--color-primaryBlue-900),rgba(18,26,38,0))] !rounded-none"
              (click)="previousPage(sourcesScroll)"
            >
              <i class="icon-ChevronLeft"></i>
            </button>
          </div>
          <div class="absolute right-0 top-0 h-9 flex items-center z-[1]">
            <button
              class="btn-icon link small bg-[linear-gradient(to_left,var(--color-primaryBlue-900),var(--color-primaryBlue-900),rgba(18,26,38,0))] !rounded-none"
              (click)="nextPage(sourcesScroll)"
            >
              <i class="icon-ChevronRight"></i>
            </button>
          </div>
          }
        </div>
        <chatbox
          #chatbox
          (sendMessage)="sendMessage($event)"
          [isThinking]="isThinking"
          [userId]="currentUser().uuid"
          [modelsList]="modelsList"
          (modelsChangeEvent)="onModelsChange($event)"
          [sourcesList]="sources"
          (sourceChangeEvent)="onSourceChange($event)"
          [hasSourceFeature]="true"
          (onAddFileEvent)="showUploadSourceDialog.showModal()"
          (onSuggestionEvent)="onSuggestionEvent()"
        />
      </div>
    </div>
    <div
      class="absolute bottom-2 caption text-primaryBlue-200 w-full flex flex-col md:flex-row md:gap-2 justify-center text-center"
    >
      <span>AI có thể đưa ra thông tin không chính xác. Hãy luôn kiểm tra lại.</span
      ><a href="https://support.google.com/gemini?p=privacy_notice">Xem chính sách và quyền sử dụng</a>
    </div>
  </div>
  @if(showSources){
  <div class="source-sidebar">
    <div class="source-header" *ngIf="selectingSource == ''">
      <div class="flex gap-1 items-center">
        <button class="btn-icon" (click)="showSources = false">
          <i class="icon-Sidebar hidden lg:inline"></i><i class="icon-XClose lg:hidden"></i>
        </button>
        <h6>Các tài liệu</h6>
      </div>
      <button
        class="btn-icon link"
        *ngIf="selectingSource == ''"
        [matMenuTriggerFor]="fileSelectionMenu"
        [disabled]="isThinking"
      >
        <i class="icon-PlusCircle"></i>
      </button>
      <!-- <button class="btn-icon link" (click)="showSources = false"><i class="icon-PlusCircle"></i></button> -->
    </div>
    <div class="col gap-4 lg:overflow-hidden h-full">
      @if(selectingSource == ''&&sources.length > 0){
      <div class="checkbox flex justify-between px-4 lg:px-0">
        <span>Chỉ hỏi trong tài liệu</span>
        <input
          type="checkbox"
          [(ngModel)]="details.onlySource"
          (ngModelChange)="onSelectOnlySource($event)"
          [disabled]="updatingWorkspace"
        />
      </div>
      <hr class="border-blueGrey-700" />
      <div class="checkbox flex justify-between px-4 lg:px-0">
        <span>Chọn tất cả tài liệu</span>
        <input
          type="checkbox"
          [(ngModel)]="selectAll"
          (ngModelChange)="onSelectAll($event)"
          [disabled]="updatingSource"
        />
      </div>
      }
      <div class="col gap-2 p-4 lg:px-0" [ngClass]="{ ' overflow-hidden h-full': selectingSource != '' }">
        @for(source of sources;track source.id;let i = $index){
        <div
          class="col justify-between gap-3"
          *ngIf="selectingSource == '' || selectingSource == source.id"
          [ngClass]="{ 'h-full overflow-hidden': selectingSource == source.id }"
        >
          <div class="flex justify-between gap-1 items-center checkbox">
            <div class="flex gap-2 cursor-pointer w-full">
              <i class="icon-File01"></i>
              <a class="text- inline-grid" title="{{ source.name }}" (click)="onClickSource(source.id)"
                ><span class="line-clamp-1">
                  {{ source.name }}
                </span></a
              >
            </div>
            @if(source.processing){
            <ng-lottie [options]="processingOptions" height="24px" width="24px"></ng-lottie>
            }@else{ @if(selectingSource){
            <button class="btn-icon link small" (click)="onClickSource(source.id)">
              <i class="icon-XClose"></i>
            </button>
            }@else{<input
              type="checkbox"
              [checked]="source.using"
              (change)="onSelectSource($event, source.id)"
              [disabled]="updatingSource"
            />}}
          </div>
          @if(selectingSource === source.id){
          <hr />
          <div
            class="col gap-4 overflow-auto mt-2"
            [ngClass]="{ 'h-[calc(100%_-_36px)]': selectingSource == source.id }"
          >
            <!-- <hr />
              <div class="col px-4 py-3 rounded-lg bg-yellow-4 border-2 border-yellow-2">
                <div class="w-full">
                  <div>👉 Nguồn tài liệu nói về?</div>
                  <div class="col gap-6 max-h-[50vh] overflow-auto">
                    <div class="col gap-2">
                      <caption>
                        Tổng hợp
                      </caption>
                      <span>{{ source.summary }}</span>
                    </div>
                  </div>
                </div>
              </div> -->
            <div class="col px-4 py-3 rounded-lg bg-emerald-5 border-2 border-emerald-2 h-full overflow-hidden">
              <div class="w-full h-full overflow-auto">
                <div>📝 Nguyên gốc</div>
                <div class="col gap-6">
                  <div class="col gap-2">
                    <!-- <caption>
                        Tổng hợp
                      </caption> -->
                    <div class="col gap-2 break-words">
                      @for (item of source.transcript; track $index) {
                      <span id="page-{{ item.page }}">{{ item.text }}</span>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
        } @empty {
        <div class="col items-center gap-6 lg:py-14">
          <img src="assets/images/Empty States.svg" alt="" class="w-[150px]" />
          <p class="text-center">Ở đây chưa có tài liệu nè,<br />tải lên nha</p>
        </div>
        }
      </div>
    </div>
  </div>
  }
</div>
<dialog #errorDialog (click)="errorDialog.close()" [open]="error != ''">
  <div class="col w-full items-center gap-6 p-6 pt-10" (click)="$event.stopPropagation()">
    <span class="line-clamp-2">{{ error }}</span>
    <button class="btn orange" (click)="errorDialog.close()">Đã hiểu</button>
  </div>
</dialog>
<div #selectionDialog (click)="deselect()" class="fixed top-0 left-0 right-0 bottom-0 z-20 hidden">
  <div
    #selectionActions
    class="fixed w-auto flex gap-1 rounded-lg bg-white border border-blueGrey-700"
    (click)="$event.stopPropagation()"
  >
    <!-- <span>{{ selectedText }}</span> -->
    <button class="btn-icon link" (click)="copy()">
      <i class="icon-Duplicate"></i>
    </button>
    <button class="btn-icon link" (click)="setMessage()">
      <i class="icon-MessageTextSquare"></i>
    </button>
  </div>
</div>
<mat-menu #fileSelectionMenu xPosition="before" yPosition="above" class="h-[250px] w-[250px]">
  <button mat-menu-item (click)="showFileSelection = true">
    <i class="icon-File"></i>
    <span>Chọn từ quản lý tập tin</span>
  </button>
  <button mat-menu-item (click)="inputFile.value = ''; inputFile.click()">
    <i class="icon-Upload"></i>
    <span>Chọn từ máy tính</span>
  </button>
  <div class="border-t border-dashed px-4 my-2"></div>
  <div class="flex gap-1.5 px-4 text-blueGrey-500">
    <i class="icon-Error small"></i>
    <span>
      Workspace hỗ trợ tải lên:<br />
      <ul class="list-disc">
        <li>Tài liệu PDF: kích thước &lt; 20 MB</li>
        <li>Cho phép tải lên nhiều tài liệu cùng lúc.</li>
      </ul>
    </span>
  </div>
</mat-menu>
<!-- <input
  #file
  hidden
  type="file"
  (change)="onFileSelected($event)"
  [accept]="accept"
  placeholder="Chọn file"
  (cancel)="onFileCancel()"
/> -->
<input
  #inputFile
  hidden
  multiple
  type="file"
  (change)="onFileSelected($event)"
  [accept]="accetp"
  placeholder="Chọn file"
  (cancel)="onFileCancel()"
/>
<div
  class="fixed top-0 left-0 w-full h-screen overflow-hidden flex items-center justify-center p-4 bg-blueGrey-100 z-20"
  *ngIf="showFileSelection"
>
  <div class="absolute top-0 left-0 right-0 bottom-0" (click)="showFileSelection = false"></div>
  <div class="w-full max-w-[777px] flex flex-col gap-6 bg-white rounded-lg p-6 relative h-[80%]">
    <file-selection
      (fileClicked)="onSelectFileFromStorage($event)"
      [fileService]="fileService"
      [userId]="userId"
      [multiple]="true"
      (filesSelected)="onFilesSelected($event)"
      (onClose)="onCloseFileSelection()"
      [filterExtensions]="['pdf']"
    />
  </div>
</div>
<dialog #showUploadSourceDialog (click)="showUploadSourceDialog.close()">
  <div class="col w-full items-center max-w-[322px] relative" (click)="$event.stopPropagation()">
    <button class="btn-icon link absolute top-4 right-4" (click)="showUploadSourceDialog.close()">
      <i class="icon-XClose"></i>
    </button>
    <div class="col gap-4 p-6">
      <div class="flex justify-between w-full">
        <h6 class="semi">Tải tài liệu</h6>
      </div>
      <div class="col gap-2 w-full items-start caption">
        <button class="btn link !font-normal" (click)="showFileSelection = true; showUploadSourceDialog.close()">
          <i class="icon-File"></i>
          <span>Chọn từ quản lý tập tin</span>
        </button>
        <button
          class="btn link !font-normal"
          (click)="inputFile.value = ''; inputFile.click(); showUploadSourceDialog.close()"
        >
          <i class="icon-Upload"></i>
          <span>Chọn từ máy tính</span>
        </button>
      </div>
      <div class="flex gap-2 px-4 caption">
        <i class="icon-Error small"></i>
        <span class="col gap-2">
          Workspace hỗ trợ tải lên:<br />
          <ul class="list-disc col gap-1.5">
            <li>Tài liệu PDF: kích thước &lt; 20 MB</li>
            <li>Cho phép tải lên nhiều tài liệu cùng lúc.</li>
          </ul>
        </span>
      </div>
    </div>
  </div>
</dialog>
<dialog #candidateDialog (click)="candidateDialog.close()" class="!max-w-[700px] h-full">
  <div
    class="col w-full items-center gap-4 p-6 pt-10 rounded-2xl h-full overflow-auto"
    (click)="$event.stopPropagation()"
  >
    <div class="flex justify-between w-full">
      <h6 class="semi">{{ sourceCandidate?.name }}</h6>
      <button class="btn-icon link" (click)="candidateDialog.close()">
        <i class="icon-XClose"></i>
      </button>
    </div>
    <p *ngIf="candidate && candidate.page != undefined">Trang: {{ candidate.page }}</p>
    @if(sourceCandidate!=undefined){
    <div class="relative h-[80vh] w-full bg-[#313131]">
      <ng-lottie
        [options]="options"
        width="100px"
        height="100px"
        class="absolute top-[calc(50%_-_50px)] left-[calc(50%_-_50px)]"
      ></ng-lottie>
      <!-- <embed
        [src]="sourceCandidate.publicUrl | safeResourceUrl"
        width="100%"
        height="100%"
        class="w-full h-[80vh] min-h-[80vh] relative"
      /> -->
      <iframe [src]="sourceCandidatePublicUrl" frameborder="0" class="w-full aspect-[3/4] relative"></iframe>
    </div>
    }
    <div class="col gap-2 w-full items-start">
      <h5 class="semi">Văn bản liên quan</h5>
      <p>{{ candidate?.text }}</p>
    </div>
  </div>
</dialog>

<dialog #editDialog (click)="editDialog.close()">
  <div class="col w-full items-stretch" (click)="$event.stopPropagation()">
    <button class="btn-icon link self-end" (click)="editDialog.close()"><i class="icon-XClose"></i></button>
    <workspace-edit
      [workspace]="details"
      (closeOnEditEvent)="onEdited($event); editDialog.close()"
      (closeOnDeleteEvent)="onDeleted($event); editDialog.close()"
    ></workspace-edit>
  </div>
</dialog>
