import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ManaController } from '@app/mana.controller';
import { ChatService } from '@data/chat/chat.service';
import { FileService } from '@data/file/file.service';
import { TrackingService } from '@data/tracking/tracking.service';
import { UserService } from '@data/user/user.service';
import { Source, Workspace } from '@data/workspace/workspace-models';
import { WorkspaceService } from '@data/workspace/workspace.service';
import { ChatboxComponent, FileSelectionComponent, MessagesComponent } from '@share-components';
import { AIModel, Candidate, Content, ErrorContent, FileData, FilePlaceholder, LikeContent, Part, TextPart } from '@share-utils/data';
import { ISource, Role } from '@share-utils/domain';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { firstValueFrom, zip } from 'rxjs';
import { WorkspaceEditComponent } from '../workspace-edit/workspace-edit.component';

@Component({
  standalone: true,
  imports: [CommonModule, ChatboxComponent, MessagesComponent, LottieComponent, MatMenuModule, FileSelectionComponent, FormsModule, WorkspaceEditComponent],
  templateUrl: './workspace-details.component.html',
  styleUrl: './workspace-details.component.scss'
})
export class WorkspaceDetailsComponent implements OnInit, AfterViewInit {
  fileService = inject(FileService);
  accetp = 'application/pdf';
  workspaceService = inject(WorkspaceService);
  userId = inject(UserService).currentUser().uuid;
  route = inject(ActivatedRoute);
  currentUser = inject(UserService).currentUser;
  trackingService = inject(TrackingService);
  chatService = inject(ChatService);
  manaCtrl = inject(ManaController);
  renderer = inject(Renderer2);
  titleService = inject(Title);
  accept = this.fileService.accept;
  sanitizer = inject(DomSanitizer);
  router = inject(Router);

  @ViewChild('inputFile') inputFileElm!: ElementRef<HTMLInputElement>;
  @ViewChild('errorDialog') errorDialogElm!: ElementRef<HTMLDialogElement>;
  @ViewChild('workspace') workspaceElm!: ElementRef<HTMLDivElement>;
  @ViewChild('selectionDialog') selectionDialogElm!: ElementRef<HTMLDivElement>;
  @ViewChild('selectionActions') selectionActionsElm!: ElementRef<HTMLInputElement>;
  @ViewChild('chatbox') chatboxComponent!: ChatboxComponent;
  // @ViewChild('onlySource') onlySourceElm!: ElementRef<HTMLInputElement>;
  @ViewChild('showUploadSourceDialog') showUploadSourceDialogElm!: ElementRef<HTMLDialogElement>;
  @ViewChild('candidateDialog') candidateDialogElm!: ElementRef<HTMLDialogElement>;

  error = '';
  showSources = false;
  showSourceDetails = false;
  details = Workspace.empty();
  totalFiles = 0;
  uploadingSources: { fileId: string, fileName: string, publicUrl: string, extension: string }[] = [];
  openSummary = false;
  messages: (Content | ErrorContent)[] = [];
  isThinking = false;
  workspaceId = '';
  sources: Source[] = [];
  selectingSource = '';
  updatingSource = false;
  updatingWorkspace = false;
  options: AnimationOptions = {
    path: './assets/animations/loading.json',
    loop: true,
    autoplay: true,
  };
  processingOptions: AnimationOptions = {
    path: './assets/animations/file-processing.json',
    loop: true,
    autoplay: true,
  };
  selectedText = '';
  file: File | null = null;
  showFileSelection = false;
  placeholder: FilePlaceholder | null = null;
  isCopied = false;
  modelsList: AIModel[] = [AIModel.workspace(), AIModel.kyo()];
  selectedModels: AIModel[] = [];
  showActions = false;
  currentUrl = '';
  selectAll = false;
  candidate: Candidate | undefined = undefined;
  sourceCandidate: Source | undefined = undefined;
  sourceCandidatePublicUrl: SafeUrl | undefined = undefined;

  ngOnInit(): void {
    this.titleService.setTitle('KYONS AI - Workspace');
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) return;
    this.workspaceId = id;
    this.updateWorkspace();
    this.updateSources();
    this.getMessages();
    window.onpopstate = (event) => {
      // Check if the URL has changed
      if ((window.location.origin + window.location.pathname) == this.currentUrl) {
        // Handle the URL change here
        console.log('URL changed:', window.location.href);
        const ref = window.location.hash.replace('#', '').split('-');
        event.preventDefault();
        if (ref.length == 2) {
          this.candidate = this.messages.find(message => message.id == ref[0])!.candidates?.find(candidate => candidate.citation.toString() == ref[1]);
          this.sourceCandidate = this.sources.find(source => source.fileId == this.candidate?.fileId)!;
          this.sourceCandidatePublicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.sourceCandidate.publicUrl);
          if (this.sourceCandidate) this.candidateDialogElm.nativeElement.showModal();
          else {
            this.messages.push(Content.empty())
          }
        }
      }
    };

    // Store the initial URL
    this.currentUrl = window.location.origin + window.location.pathname;
  }

  ngAfterViewInit(): void {
    this.renderer.listen(this.workspaceElm.nativeElement, 'mouseup', (event: MouseEvent) => {
      this.onSelectionChange(event);
    });
  }

  @HostListener('document:keydown.esc', ['$event'])
  onKeydown(event: KeyboardEvent) {
    // Handle Esc key press here
    if (event.key === 'Escape') {
      this.chatboxComponent.showModels = false;
    }
  }

  updateWorkspace() {
    firstValueFrom(this.workspaceService.getWorkspace(this.userId, this.workspaceId)).then((workspace) => {
      this.details = workspace;
      if (this.details.summary) {
        this.openSummary = true;
      }
      this.updatingWorkspace = false;
    });
  }

  updateSources() {
    firstValueFrom(this.workspaceService.getSources(this.userId, this.workspaceId)).then((sources) => {
      this.sources = sources;
      if (sources.length == 0) {
        this.showUploadSourceDialogElm.nativeElement.showModal();
      }
      this.selectAll = sources.every(source => source.using);
    })
  }

  onSelectAll(isChecked: boolean) {
    this.sources.forEach(source => {
      firstValueFrom(this.workspaceService.selectSource(this.userId, this.workspaceId, source.id, isChecked)).then(() => {
        source.using = isChecked;
      }, (error) => {
        console.log(error);
      });
    });
  }

  addFiles() {
    this.uploadingSources = [];
    this.totalFiles = 0;
    this.inputFileElm.nativeElement.value = '';
    this.inputFileElm.nativeElement.click();
  }

  onFileSelected($event: Event) {
    if ($event.target == null) return;
    this.updatingSource = true;
    this.showSources = true;
    this.isThinking = true;
    const target = $event.target as HTMLInputElement;
    // convertFile(files[0]).subscribe(base64 => {
    //   this.image = base64;
    // });
    if (target.files) {
      this.totalFiles = target.files.length;
      for (let i = 0; i < target.files.length; i++) {
        const file = target.files[i];
        if (file.size > 20 * 1024 * 1024) {
          alert("Không thể tải tập tin lớn hơn 20 MB");
          return;
        }
        // const reader = new FileReader();
        // reader.readAsDataURL(file);
        const image = new FilePlaceholder(file.name, file.type, file.size);
        // reader.onloadend = this.onloadend.bind(this, reader, file, image);
        this.onloadend(file, image);
      }
      // const 
      // if (file.type.split('/')[0] !== 'image' && file.size > 5 * 1024 * 1024) {
      //   alert("Không thể tải ảnh lớn hơn 5 MB");
      //   return;
      // }
      // this.file = file;
      // this.image = new FilePlaceholder(this.file.name, this.file.type, this.file.size);
    }
  }

  onloadend(file: File, image: FilePlaceholder) {
    const placeholder = Source.fromFilePlaceholder(image);
    this.sources.push(placeholder);
    this.uploadingSources = [];
    firstValueFrom(this.fileService.uploadFile(this.userId, file, image, this.workspaceId)).then((res) => {
      console.log(res);
      this.uploadingSources.push({ fileId: res.id, fileName: res.name, publicUrl: res.publicUrl, extension: res.extension });
      if (this.totalFiles == this.uploadingSources.length) {
        firstValueFrom(this.workspaceService.createSources(this.userId, this.workspaceId, this.uploadingSources)).then((res) => {
          console.log(res);
          // if (this.totalFiles == 1) {
          //   console.log('open source');
          // }
          // else {
          //   console.log('open general summary');
          // }
          this.updatingSource = false;
          this.isThinking = false;
          // this.openSummary = true;
          // this.updateWorkspace();
          this.updateSources();
        }, (error) => {
          this.updatingSource = false;
          this.isThinking = false;
          console.error(error);
          this.error = JSON.stringify(error);
          this.errorDialogElm.nativeElement.showModal();
        })
      }
    }, (error) => {
      this.updatingSource = false;
      this.isThinking = false;
      console.error(error);
      this.error = JSON.stringify(error);
      this.errorDialogElm.nativeElement.showModal();
    })
  }

  async sendMessage(message: string) {
    if (this.isThinking) return;
    this.isThinking = true;
    const askingMessage: Part[] = [new TextPart(message)];
    this.messages = [...this.messages, new Content({ id: '', role: Role.user, parts: askingMessage, createdAt: new Date() })];
    const requestList: Promise<any>[] = [];
    if (this.selectedModels.length > 0) {
      this.selectedModels.map(model => {
        if (model.hashTag == '@KyoAI') {
          let prompt = message;
          this.selectedModels.filter(m => m.hashTag != '@KyoAI').map(m => m.hashTag).forEach(hashTag => {
            const regex = new RegExp(`\\s*${hashTag}\\s*`, 'g');
            prompt = prompt.replace(regex, ' ');
          });
          requestList.push(firstValueFrom(this.chatService.sendMessage(this.currentUser().uuid, prompt, model.hashTag, {
            chatId: this.workspaceId,
            responseLanguage: this.details.language,
          })))
        }
        else if (model.hashTag == '@Workspace') {
          let prompt = message;
          this.selectedModels.filter(m => m.hashTag != '@Workspace').map(m => m.hashTag).forEach(hashTag => {
            const regex = new RegExp(`\\s*${hashTag}\\s*`, 'g');
            prompt = prompt.replace(regex, ' ');
          }); console.log(message);
          requestList.push(firstValueFrom(this.workspaceService.sendMessage(this.currentUser().uuid, prompt, model.hashTag, {
            chatId: this.workspaceId,
          })))
        }
      })
    }
    this.details.suggestions = [];
    firstValueFrom(this.workspaceService.clearSuggestions(this.userId, this.workspaceId));
    firstValueFrom(zip(...requestList)
      // .pipe(
      //   catchError((err) => {
      //     console.error(err);
      //     if (err.code) {
      //       return [...this.messages, ErrorContent.fromCode(err.code)]
      //     }
      //     else {
      //       return [...this.messages, Content.unknownError(err.code)]
      //     }
      //   }),
      // )
    ).then(
      (res) => {
        this.getMessages();
        this.updateWorkspace();
        this.manaCtrl.refresh();
        this.trackingService.updateTrackOnSendMessage();
      }, (err) => {
        console.error(err);
        if (err.code) {
          this.messages = [...this.messages, ErrorContent.fromCode(err.code, this.selectedModels[0])]
        }
        else {
          this.messages = [...this.messages, Content.unknownError(err.code)]
        }
        this.isThinking = false;
      },
    )
    // firstValueFrom(this.workspaceService.sendMessage(this.currentUser().uuid, message, {
    //   chatId: this.workspaceId,
    // })).then(async (chatId) => {
    //   this.getMessages();
    //   this.manaCtrl.refresh();
    //   this.trackingService.updateTrackOnSendMessage();
    // }, (err) => {
    //   console.error(err);
    //   if (err.code === 3) {
    //     this.messages = [...this.messages, Content.outOfMana()]
    //   }
    //   else {
    //     this.messages = [...this.messages, Content.unknownError(err.code)]
    //   }
    //   this.isThinking = false;
    // });
    // if (this.messages.length == 1) {
    //   this.chatService.removeChatCache();
    // }
  }


  getMessages() {
    firstValueFrom(this.chatService.getMessages(this.currentUser().uuid, this.workspaceId)).then(
      (messages) => {
        this.messages = messages;

        this.isThinking = false;
        if (this.messages.length == 0) {
          this.showActions = true;
        }
        else {
          this.showActions = false;
        }
      },
      (err) => {
        console.error(err);
        if (err.code === 2) {
          firstValueFrom(this.workspaceService.createWorkspaceChat(this.currentUser().uuid, this.workspaceId)).then((chatId) => {
            this.getMessages();
            this.chatService.removeChatCache();
          })
        }
        this.isThinking = false;
      },
    );
  }

  updateThinking(isThinking: boolean) {
    this.isThinking = isThinking;
  }

  previousPage(scrollElm: HTMLElement) {
    scrollElm.scrollLeft -= 300;
  }

  nextPage(scrollElm: HTMLElement) {
    scrollElm.scrollLeft += 300;
  }

  onSelectSource($event: Event, sourceId: string) {
    const checkboxElement = $event.target as HTMLInputElement;
    const isChecked = checkboxElement.checked;
    // console.log(isChecked, sourceId);
    this.updateUsing(sourceId, isChecked);

  }

  updateUsing(sourceId: string, isChecked: boolean) {
    this.updatingSource = true;
    const source = this.sources.find(source => source.id === sourceId);
    if (source) source.using = isChecked;
    firstValueFrom(this.workspaceService.selectSource(this.userId, this.workspaceId, sourceId, isChecked)).then((res) => {
      this.selectingSource = '';
      this.updatingSource = false;
      this.selectAll = this.sources.every(source => source.using);
    }, (err) => {
      this.selectingSource = '';
      this.updatingSource = false;
      console.error(err);
    });
  }

  sendDefinedMessage(type: string) {
    if (type == 'faq') {
      this.sendMessage('FAQ');
    }
    else if (type == 'quiz') {
      this.sendMessage('Quiz');
    }
    else if (type == 'summary') {
      this.sendMessage('Summary');
    }
    else if (type == 'plan') {
      this.sendMessage('Plan');
    }
  }

  onSelectionChange(event: MouseEvent) {
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    this.selectionActionsElm.nativeElement.style.left = mouseX + 'px';
    this.selectionActionsElm.nativeElement.style.top = mouseY + 'px';
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      this.selectedText = range.toString();
      if (this.selectedText.length > 2) this.selectionDialogElm.nativeElement.classList.remove('hidden');
    }
  }

  copy() {
    navigator.clipboard.writeText(this.selectedText);
    // this.clipboard.copy(this.selectedText);
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 1000);
    this.deselect();
  }

  setMessage() {
    this.chatboxComponent.addText(this.selectedText);
    this.deselect();
  }

  deselect() {
    // const selection = window.getSelection();
    // if (selection && selection.rangeCount > 0) {
    //   selection.removeAllRanges();
    // }
    this.selectionDialogElm.nativeElement.classList.add('hidden');
  }

  onLikeMessage({ messageId, like }: { messageId: string; like: LikeContent; }) {
    firstValueFrom(this.chatService.likeContent(this.currentUser().uuid, this.workspaceId, messageId, like)).then(
      (res) => {
        this.messages.find(message => message.id === messageId)!.like = like;
      }, (err) => {
        console.error(err);
      }
    );
  }

  onFileCancel() {
    this.file = null;
    this.inputFileElm.nativeElement.value = '';
  }

  onSelectFileFromStorage(file: FileData) {
    this.updatingSource = true;
    this.isThinking = true;
    const placeholder = Source.fromFileData(file);
    this.sources.push(placeholder);
    firstValueFrom(this.workspaceService.createSources(this.userId, this.workspaceId, [{ fileId: file.id, fileName: file.name, publicUrl: file.fileUri, extension: file.extension }])).then(
      (res) => {
        this.updatingSource = false;
        this.isThinking = false;
        this.updateSources();
      }, (error) => {
        this.updatingSource = false;
        this.isThinking = false;
        console.error(error);
        this.error = JSON.stringify(error);
        this.errorDialogElm.nativeElement.showModal();
      }
    );
    this.showFileSelection = false;
  }

  onModelsChange(models: AIModel[]) {
    this.selectedModels = models;
  }

  isAnySourceUsing(): boolean {
    return this.sources.some((s) => s.using);
  }


  onClickSource(sourceId: string) {
    this.showSourceDetails = !this.showSourceDetails;
    if (this.selectingSource == '') this.selectingSource = sourceId;
    else this.selectingSource = '';
  }

  onSourceChange(source: ISource) {
    this.updatingSource = true;
    firstValueFrom(this.workspaceService.selectSource(this.userId, this.workspaceId, source.id, true)).then(
      (res) => {
        this.updatingSource = false;
        this.updateSources();
      }, (error) => {
        this.updatingSource = false;
        console.error(error);
        this.error = JSON.stringify(error);
        this.errorDialogElm.nativeElement.showModal();
      }
    );
  }

  onFilesSelected(files: FileData[]) {
    this.updatingSource = true;
    this.showSources = true;
    this.isThinking = true;
    this.uploadingSources = [];
    this.totalFiles = files.length;
    files.forEach((file) => {
      const placeholder = Source.fromFileData(file);
      this.sources.push(placeholder);
      firstValueFrom(this.workspaceService.createSources(this.userId, this.workspaceId, [{ fileId: file.id, fileName: file.name, publicUrl: file.fileUri, extension: file.extension }])).then(
        (res) => {
          this.uploadingSources.push({ fileId: file.id, fileName: file.name, publicUrl: file.fileUri, extension: file.extension });
          if (this.uploadingSources.length == this.totalFiles) {
            this.updatingSource = false;
            this.isThinking = false;
            this.updateSources();
          }
        }, (error) => {
          this.updatingSource = false;
          this.isThinking = false;
          console.error(error);
          this.error = JSON.stringify(error);
          this.errorDialogElm.nativeElement.showModal();
        }
      )
    })
  }

  onCloseFileSelection() {
    this.showFileSelection = false;
  }

  onSelectOnlySource(isChecked: boolean) {
    this.updatingWorkspace = true;
    firstValueFrom(this.workspaceService.editWorkspace(this.userId, this.workspaceId, { onlySource: isChecked })).then(
      (res) => {
        this.updateWorkspace();
      }, (error) => {
        this.updatingWorkspace = false;
        console.error(error);
        this.error = JSON.stringify(error);
        this.errorDialogElm.nativeElement.showModal();
      }
    );
  }

  onSuggestionEvent() {
    this.isThinking = true;
    firstValueFrom(this.workspaceService.getSuggestions(this.userId, this.workspaceId)).then(
      (suggestions) => {
        this.details.suggestions = suggestions;
        this.chatboxComponent.renderTextInput();
        this.isThinking = false;
      }
    )
  }

  onUndoMessage(message: Content) {
    firstValueFrom(this.chatService.deleteMessageFrom(this.userId, this.workspaceId, message.createdAt)).then(
      (res) => {
        this.getMessages();
      }, (err) => {
        console.error(err);
      }
    )
  }

  onRemoveMessage(message: Content) {
    firstValueFrom(this.chatService.deleteMessage(this.userId, this.workspaceId, message.id)).then(
      (res) => {
        this.getMessages();
      }, (err) => {
        console.error(err);
      }
    )
  }

  goBack() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }
  onDeleted($event: boolean) {
    this.goBack();
  }
  onEdited($event: any) {
    this.updateWorkspace();
  }

}
