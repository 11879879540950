import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IntercepterObserverDirective } from '@share-directives';
import { AIModel, Content, ErrorContent, FileData, FilePart, LikeContent, Part, TextPart } from '@share-utils/data';
import { IFileService, IGeneralChatService, Role } from '@share-utils/domain';
import { ParseOptions } from 'ngx-markdown';
import { LatexComponent } from '../../latex/latex.component';

const parseOption: ParseOptions = {
  decodeHtml: false,
  inline: false,
  emoji: false,
  mermaid: false,
  disableSanitizer: true,
};

@Component({
  selector: 'messages',
  standalone: true,
  imports: [CommonModule, LatexComponent, IntercepterObserverDirective, MatIconModule],
  templateUrl: './messages.component.html',
})
export class MessagesComponent implements AfterViewInit, OnChanges {
  clickSuggestion(suggestion: string) {
    this.onClickSuggestion.emit(suggestion);
    this.suggestions = [];
  }
  LikeContent = LikeContent;
  @Input() messages: (Content | ErrorContent)[] = [];
  @Input() isThinking = false;
  @Input() userId = '';
  @Input() chatId = '';
  @Input() fileService?: IFileService = undefined;
  @Input() chatService!: IGeneralChatService;
  @Input() suggestions: string[] = [];
  @Input() typingAnimation = true;
  @Input() interactiveModels: AIModel[] = [AIModel.kyo()];
  @Input() reviewMessage = '';


  completeText = '';
  writingText = '';
  writingMarkdown = '';
  isWriting = false;
  completeMessages: Content[] = [];
  thinkingText = '';
  willChangeMessage = Content.empty();
  willDo: 'undo' | 'remove' | '' = '';

  @Output() startGame = new EventEmitter<void>();
  @Output() isWritingEvent = new EventEmitter<boolean>();
  @Output() likeEvent = new EventEmitter<{ messageId: string, like: LikeContent }>();
  @Output() onClickSuggestion = new EventEmitter<string>();
  @Output() undoEvent = new EventEmitter<Content>();
  @Output() removeEvent = new EventEmitter<Content>();

  @ViewChild('chatBody') chatBodyElm!: ElementRef<HTMLDivElement>;

  ngAfterViewInit() {
    this.chatBodyElm.nativeElement.scrollTop = this.chatBodyElm.nativeElement.scrollHeight;
  }

  getText(part: Part) {
    if (part instanceof TextPart) {
      return part.text;
    }
    return '';
  }
  hasPlayBtn(part: Part) {
    if (part instanceof TextPart) {
      return part.hasPlayBtn;
    }
    return false;
  }

  scrollToBottom() {
    this.chatBodyElm.nativeElement.scrollTop = this.chatBodyElm.nativeElement.scrollHeight;
  }

  scroll50Percent() {
    if (this.chatBodyElm) this.chatBodyElm.nativeElement.scrollTop += this.chatBodyElm.nativeElement.clientHeight * 0.8;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['messages']) {
      if (changes['messages'].previousValue && changes['messages'].currentValue && changes['messages'].previousValue.length == changes['messages'].currentValue.length - 1) {
        const messages = changes['messages'].currentValue;
        if (!this.typingAnimation) {
          this.completeMessages.push(messages[messages.length - 1]);
        }
        else {
          const lastMessage = messages[messages.length - 1];
          if (lastMessage instanceof Content && lastMessage.role == Role.model) {
            this.completeMessages[this.completeMessages.length - 1] = messages[messages.length - 2];
            this.isWriting = true;
            this.isWritingEvent.emit(true);
            this.typeWriting(lastMessage);
          }
          else {
            this.completeMessages.push(lastMessage);
            // if (changes['messages'].currentValue.length > 0) {
            setTimeout(() => {
              this.scrollToBottom();
            }, 200);
            // }
          }
        }
      }
      else {
        if (changes['messages'].isFirstChange() || changes['messages'].previousValue.length != changes['messages'].currentValue.length || changes['messages'].previousValue[changes['messages'].previousValue.length - 1].id != changes['messages'].currentValue[changes['messages'].currentValue.length - 1].id) {
          this.completeMessages = this.messages;
          // if (changes['messages'].currentValue.length > 0) {
          setTimeout(() => {
            this.scrollToBottom();
          }, 500);
        }
        // }
      }
    }
    if (changes['isThinking']) {
      // if (changes['isThinking'].currentValue) {
      //   this.thinkingText = '...';
      // }
      // else {
      if (!this.typingAnimation) {
        setTimeout(() => {
          this.scroll50Percent();
        }, 500);
      }
    }
    if (changes['reviewMessage']) {
      this.scroll50Percent();
    }
    // }
  }

  typeWriting(lastMessage: Content) {
    this.completeText = lastMessage.parts.map(part => this.getText(part)).join('<br>');
    if (this.writingText.length < this.completeText.length) {
      this.writingText += this.completeText.charAt(this.writingText.length);
      this.completeText = this.completeText.slice(1);
      this.scrollToBottom();
      setTimeout(() => {
        this.typeWriting(lastMessage);
      }, Math.random());
    }
    else {
      this.completeMessages.push(lastMessage);
      this.isWriting = false;
      this.completeText = '';
      this.writingText = '';
      this.isWritingEvent.emit(false);

      setTimeout(() => {
        this.scrollToBottom();
      }, 200);
    }
  }

  play() {
    this.startGame.emit();
  }

  isIntersecting(status: boolean, contentIndex: number, partIndex: number) {
    if (!this.fileService) return;
    if (status) {
      const part = this.messages[contentIndex].parts[partIndex];
      if (part.isData) {
        if (part.url || (part.data && part.data.fileUri)) {
          return;
        }
        const filePart = part as FilePart;
        if (filePart.id == filePart.name) {
          return; // Case uploading
        }
        this.fileService.getFile(this.userId, filePart.id).subscribe({
          next: (data: FileData | null) => {
            if (data == null) return;
            if (data.id == 'deleted') {
              filePart.isDeleted = true;
            }
            else {
              filePart.data = data;
            }
            this.messages[contentIndex].parts[partIndex] = filePart;
          }
        });
      };
    }
  }

  copy(string: string) {
    navigator.clipboard.writeText(string);
    alert('Sao chép thành công!');
  }

  like(messageId: string, like: LikeContent) {
    this.likeEvent.emit({ messageId, like })
  }

  undo(undoMessage: Content) {
    this.willChangeMessage = undoMessage;
    this.willDo = 'undo';
  }
  remove(removeMessage: Content) {
    this.willChangeMessage = removeMessage;
    this.willDo = 'remove';
  }
  confirm() {
    if (this.willChangeMessage.id == '') return;
    if (this.willDo == 'undo') {
      this.undoEvent.emit(this.willChangeMessage);
    }
    else if (this.willDo == 'remove') {
      this.removeEvent.emit(this.willChangeMessage);
    }
  }
}
